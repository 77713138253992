body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ant-table th,.ant-table td{
    text-align: center!important;
    padding: 10px!important;    
}
td img{
    width: 30px;
    height: auto;
}
.system td img{
    width: 80px;
    height: auto;
}
.inputLabel{
    width: 80px;
    display: inline-block;
    text-align: right;
}
.toolBox{
    width: 100%;
    height: 40px;
    padding: 0px 10px 8px 10px;
}
.toolBox .leftArea{
    float: left;
}
.toolBox .rightArea{
    float: right;
}
.greenBtn{
    background: green!important;
    border: 1px solid green!important;
}
.orangeBtn{
    background: orange!important;
    border: 1px solid orange!important;

}
.ant-btn{
    text-align: center!important;
}
.body .anticon{
    margin-left: 0px!important;
}
.ant-table-selection-column{
    width: 5%;
}
.ant-table-body,.ant-table-header{
    overflow-y: auto!important;
}
.errorPage{
    width: 100%;
    text-align: center;
    height: calc(100vh - 50px);
    background: #F0F2F5;
}
.contentPage{
    padding: 10px;
}
.ant-cascader-menu{
    height: auto!important;
}